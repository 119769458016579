<template>
  <div>
    <div class="wrapper">
      <Head />
      <!-- 內容 -->
      <section class="main case_list" v-if="Member">
        <div class="container small">
          <div class="title">
            <h1>案件管理</h1>
          </div>
          <!-- 0815 修改 -->
          <div class="list_btn">
            <button v-if="Member.Role.indexOf('話務人員') != -1 ||
              Member.Role.indexOf('外勤人員') != -1
              " :class="Status == -1 && Official_Status == -1 && ShiftChange == -1
    ? 'add'
    : ''
    " @click="
    Status = -1;
  Official_Status = -1;
  ShiftChange = -1;
  OnlyMe = 0;
  loadList(1);
  ">
              全部
            </button>
            <button v-if="Member.Role.indexOf('話務人員') != -1" :class="Status == 0 && Official_Status == 1 && ShiftChange == 0
              ? 'add'
              : ''
              " @click="
    Status = 0;
  Official_Status = 1;
  ShiftChange = 0;
  OnlyMe = -1;
  loadList(1);
  ">
              未簽收(收發)
            </button>
            <button v-if="Member.Role.indexOf('話務人員') != -1" :class="Status == 0 && Official_Status == 2 && ShiftChange == 0
              ? 'add'
              : ''
              " @click="
    Status = 0;
  Official_Status = 2;
  ShiftChange = 0;
  OnlyMe = -1;
  loadList(1);
  ">
              未指派承辦(收發)
            </button>
            <button v-if="Member.Role.indexOf('話務人員') != -1" :class="Status == 0 && Official_Status == 3 && ShiftChange == 0
              ? 'add'
              : ''
              " @click="
    Status = 0;
  Official_Status = 3;
  ShiftChange = 0;
  OnlyMe = -1;
  loadList(1);
  ">
              未簽收(承辦)
            </button>
            <button v-if="Member.Role.indexOf('話務人員') != -1" :class="Status == 0 && Official_Status == 4 && ShiftChange == 0
              ? 'add'
              : ''
              " @click="
    Status = 0;
  Official_Status = 4;
  ShiftChange = 0;
  OnlyMe = -1;
  loadList(1);
  ">
              未指派動物之家(承辦)
            </button>
            <button v-if="Member.Role.indexOf('話務人員') != -1" :class="Status == 0 && Official_Status == 5 && ShiftChange == 1
              ? 'add'
              : ''
              " @click="
    Status = 0;
  Official_Status = 5;
  ShiftChange = 1;
  OnlyMe = -1;
  loadList(1);
  ">
              換班
            </button>
            <button v-if="Member.Role.indexOf('話務人員') != -1 ||
              Member.Role.indexOf('外勤人員') != -1
              " :class="Status == 0 && Official_Status == 5 && ShiftChange == 0
    ? 'add'
    : ''
    " @click="
    Status = 0;
  Official_Status = 5;
  ShiftChange = 0;
  OnlyMe = -1;
  loadList(1);
  ">
              未簽收(外勤)
            </button>
            <button v-if="Member.Role.indexOf('話務人員') != -1 ||
              Member.Role.indexOf('外勤人員') != -1
              " :class="Status == 1 && Official_Status == 6 && ShiftChange == 0 && OnlyMe == 1
    ? 'add'
    : ''
    " @click="
    Status = 1;
  Official_Status = 6;
  ShiftChange = 0;
  OnlyMe = 1;
  loadList(1);
  ">
              處理中(外勤)
            </button>
            <button v-if="Member.Role.indexOf('話務人員') != -1 ||
              Member.Role.indexOf('外勤人員') != -1
              " :class="Status == 1 && Official_Status == 6 && ShiftChange == 0 && OnlyMe == 0
    ? 'add'
    : ''
    " @click="
    Status = 1;
  Official_Status = 6;
  ShiftChange = 0;
  OnlyMe = 0;
  loadList(1);
  ">
              處理中(動物之家)
            </button>
            <button v-if="Member.Role.indexOf('話務人員') != -1 ||
              Member.Role.indexOf('外勤人員') != -1
              " :class="Status == 2 && Official_Status == -1 && ShiftChange == 0 && OnlyMe == -1
    ? 'add'
    : ''
    " @click="
    Status = 2;
  Official_Status = -1;
  ShiftChange = 0;
  OnlyMe = 1;
  loadList(1);
  ">
              處理完成(外勤)
            </button>
            <button v-if="Member.Role.indexOf('話務人員') != -1 ||
              Member.Role.indexOf('外勤人員') != -1
              " :class="Status == 2 && Official_Status == -1 && ShiftChange == 0 && OnlyMe == 0
    ? 'add'
    : ''
    " @click="
    Status = 2;
  Official_Status = -1;
  ShiftChange = 0;
  OnlyMe = 0;
  loadList(1);
  ">
              處理完成(動物之家)
            </button>
            <button v-if="Member.Role.indexOf('話務人員') != -1" :class="Status == 3 && Official_Status == -1 && ShiftChange == -1
              ? 'add'
              : ''
              " @click="
    Status = 3;
  Official_Status = -1;
  ShiftChange = -1;
  OnlyMe = -1;
  loadList(1);
  ">
              結案
            </button>
            <button v-if="Member.Role.indexOf('話務人員') != -1" :class="Status == 9 && Official_Status == -1 && ShiftChange == -1
              ? 'add'
              : ''
              " @click="
    Status = 9;
  Official_Status = -1;
  ShiftChange = -1;
  OnlyMe = -1;
  loadList(1);
  ">
              未結案件
            </button>
            <div v-if="Member.Role.indexOf('話務人員') != -1">
              <router-link :to="{ path: '/CaseEdit', query: {} }" tag="button" class="new">新增</router-link>
            </div>
          </div>
          <div class="search_box">
            <label for="">篩選</label>
            <div class="form-group">
              <select class="form-control" v-model="Area">
                <option value="">全部行政區</option>
                <option v-for="(Area, index) in Areas" :key="index" :text="Area.Text" :value="Area.Value">
                  {{ Area.Text }}
                </option>
              </select>
              <input class="form-control" placeholder="關鍵字搜尋" v-model="Keyword" />
            </div>
            <button @click="loadList(1)">
              <img src="/assets/img/search.svg" alt="" />
            </button>
            <!-- 0914 新增日期排序 -->
            <div class="date">
              <p v-if="OrderBy == 0" @click="OrderBy = 1">
                日期：從新到舊<img src="assets/img/arrow-2.svg" alt="" />
              </p>
              <p v-if="OrderBy == 1" @click="OrderBy = 0">
                日期：從舊到新<img src="assets/img/arrow-2.svg" alt="" class="add" />
              </p>
            </div>
          </div>
          <hr />
          <div class="text_none show" v-if="CaseInfos">
            <!-- 0815 全選 -->
            <div class="CheckAll" v-show="CaseInfos.length > 0 &&
              (Official_Status == 5 ||
                (Official_Status == 6 && Status == 1)) &&
              Member.Role.indexOf('外勤人員') != -1
              && OnlyMe != 0
              ">
              <div>
                <input type="checkbox" id="CheckAll" v-model="CheckAll" />
                <label for="CheckAll">全選</label>
              </div>
              <button v-show="Official_Status == 5 && OnlyMe != 0" @click="checkOutsider()">
                簽收
              </button>
              <button v-show="Official_Status == 6 && Status == 1 && OnlyMe != 0" @click="shiftChange()">
                換班
              </button>
            </div>
            <div class="CheckAll" v-show="Member.Role.indexOf('外勤人員') == -1"></div>
            <div class="box" v-for="(CaseInfo, index) in CaseInfos" :key="index"
              :style="Official_Status == 6 && OnlyMe == 0 ? 'background-color: #edebeb;' : ''">
              <div class="collapse_btn">
                <!-- 0815 勾選格 -->
                <div class="" v-if="(Official_Status == 5 ||
                  (Official_Status == 6 &&
                    Status == 1 &&
                    CaseInfo.Outsider == Member.MId)) &&
                  Member.Role.indexOf('外勤人員') != -1
                  && OnlyMe != 0
                  ">
                  <input class="check" type="checkbox" v-model="CIIds" :value="CaseInfo.CIId" />
                </div>
                <button>
                  <img src="/assets/img/arrow.svg" alt="" class="add" />
                  <p v-if="CaseInfo.Status == 0">通報中</p>
                  <p v-if="CaseInfo.Status == 1">處理中</p>
                  <p v-if="CaseInfo.Status == 2">處理完成</p>
                  <p v-if="CaseInfo.Status == 3">結案</p>
                </button>
                <p class="priority" v-if="CaseInfo.CaseSequence == 1">優先</p>
                <p class="priority" v-if="CaseInfo.CaseSequence == 2">簡易</p>
                <p class="priority" v-if="CaseInfo.ShiftChange">換班</p>
              </div>
              <ul class="text_box">
                <li class="text">
                  <p>案件編號</p>
                  <p>{{ forView(CaseInfo.CaseInfoId) }}</p>
                </li>
                <li class="text">
                  <p>通報時間</p>
                  <p>
                    {{
                      CaseInfo.InquirerTime | timeString("YYYY-MM-DD HH:mm:ss")
                    }}
                  </p>
                </li>
                <li class="text">
                  <p>案件類型</p>
                  <p>{{ CaseInfo.CaseTypeName }}</p>
                </li>
                <li class="text">
                  <p>派案人員</p>
                  <p>{{ CaseInfo.MId_Name }}</p>
                </li>
              </ul>
              <ul class="text_box collapse" style="display: none">
                <hr class="line" />
                <li class="text">
                  <p>陳情人姓名</p>
                  <p>{{ CaseInfo.InquirerName }}</p>
                </li>
                <li class="text">
                  <p>連絡電話</p>
                  <p>{{ CaseInfo.InquirerPhone }}</p>
                </li>
                <li class="text full">
                  <p>案發地點</p>
                  <p>{{ CaseInfo.Address }}</p>
                </li>
                <li class="text full">
                  <p>案件摘要</p>
                  <p style="white-space: pre-wrap">
                    {{ CaseInfo.Summary }}
                  </p>
                </li>
                <li class="text full" v-if="CaseInfo.Work_Summary">
                  <p>外勤摘要</p>
                  <p style="white-space: pre-wrap">
                    {{ CaseInfo.Work_Summary }}
                  </p>
                </li>
                <hr class="line" />
                <li class="text" v-if="CaseInfo.MainUndertakerName">
                  <p>主要承辦人</p>
                  <p>{{ CaseInfo.MainUndertakerName }}</p>
                </li>
                <li class="text" v-if="!CaseInfo.MainUndertakerName"></li>
                <li class="text btn_box">
                  <p>操作</p>
                  <p>
                    <router-link :to="{
                      path: '/CaseEdit',
                      query: { CIId: CaseInfo.CIId },
                    }" tag="button" v-if="CaseInfo.Status < 2 &&
  (Member.Role.indexOf('話務人員') != -1 ||
    (Member.Role.indexOf('收發人員') != -1 && CaseInfo.Outsider == Member.MId))
  "><img src="/assets/img/icon_1.svg" alt="" />編輯</router-link>
                    <router-link :to="{
                      path: '/CaseService',
                      query: { CIId: CaseInfo.CIId },
                    }" tag="button" v-if="(CaseInfo.Status == 0 || CaseInfo.Status == 1) &&
  CaseInfo.Official_Status == 6 &&
  (Member.Role.indexOf('外勤人員') != -1 && CaseInfo.Outsider == Member.MId)
  "><img src="/assets/img/icon_2.svg" alt="" />勤務登記</router-link>
                    <button v-if="(CaseInfo.Status == 0 || CaseInfo.Status == 1) &&
                      CaseInfo.Official_Status == 6 &&
                      Member.Role.indexOf('外勤人員') != -1 &&
                      CaseInfo.Outsider == Member.MId
                      " @click="checkIn(CaseInfo.CIId)">
                      打卡
                    </button>
                    <button v-if="(CaseInfo.Status == 2) &&
                      (Member.Role.indexOf('話務人員') != -1 || Member.Role.indexOf('外勤人員') != -1)
                      " @click="gotoServiceView(CaseInfo.CIId)"><img src="/assets/img/icon_2.svg" alt="" />查看勤務登記</button>
                  </p>
                </li>
              </ul>
            </div>
            <!-- 頁碼 -->
            <paginate :no-li-surround="false" :page-count="pageAll" :page-range="5" :margin-pages="1"
              :click-handler="loadList" :prev-text="'<'" :next-text="'>'" :prev-link-class="'page-link'"
              :next-link-class="'page-link'" :container-class="'pagination'" :page-link-class="'page-link'"
              :active-class="'active'" :active-link-class="''" :hide-prev-next="false">
            </paginate>
          </div>
        </div>
      </section>
    </div>
    <Footer />
  </div>
</template>

<script>
import Head from "@/components/Head.vue";
import Footer from "@/components/Footer.vue";

import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import Paginate from "vuejs-paginate";

Vue.use(VueAxios, axios);
Vue.component("paginate", Paginate);

export default {
  name: "CaseList",
  components: {
    Head,
    Footer,
  },
  data() {
    return {
      Member: null,
      Areas: null,
      Area: "",
      Keyword: "",
      Status: -1,
      Official_Status: -1,
      ShiftChange: -1,
      OnlyMe: -1,
      CaseInfos: null,
      pageAll: 0,
      perPage: 5,

      CheckAll: false,
      CIIds: [],

      OrderBy: 0,
    };
  },
  watch: {
    // Status(newVal, oldVal) {
    //   this.loadList(1);
    // },
    Area(newVal, oldVal) {
      this.loadList(1);
    },
    OrderBy(newVal, oldVal) {
      this.loadList(1);
    },
    CheckAll(newVal, oldVal) {
      let that = this;
      if (newVal) {
        that.CaseInfos.forEach(function (CaseInfo, index) {
          that.CIIds.push(CaseInfo.CIId);
        });
      } else {
        that.CIIds = [];
      }
    },
  },
  created: function () {
    Vue.axios
      .get(
        this.$store.state.src +
        this.$store.state.subDirectory +
        "/Html/CaseList",
        {
          params: {
            token: $cookies.get("acm_login_token"),
          },
        }
      )
      .then((response) => {
        var CaseListData = response.data;
        if (CaseListData.error) {
          Swal.fire({
            title: "錯誤",
            text: CaseListData.error,
            icon: "error",
            allowOutsideClick: false,
            confirmButtonText: "確定",
            heightAuto: false,
            willClose: () => {
              if (CaseListData.token_error) {
                $cookies.remove("acm_login_token");
                this.$router.push({
                  name: "Login",
                });
              }
            },
          });
        } else {
          if (CaseListData.Member.IsFirstLogin) {
            let that = this;
            Swal.fire({
              title: "請變更您的密碼",
              text: "首次登入請變更密碼後再進行操作",
              icon: "info",
              allowOutsideClick: false,
              showConfirmButton: false,
              heightAuto: false,
              timer: 1500,
              willClose: () => {
                that.$router.push({
                  name: "ResetPassword",
                });
              },
            });
          }
          this.Member = CaseListData.Member;
          this.Areas = CaseListData.Areas;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  mounted: function () {
    this.loadList(1);
  },
  updated: function () {
    $(".case_list .box .collapse_btn button").off("click");
    $(".case_list .box .collapse_btn button").on("click", function () {
      $(this).find("img").toggleClass("add");
      $(this).parent().siblings(".collapse").slideToggle();
    });
  },
  methods: {
    loadList: function (page) {
      var that = this;
      $.ajax({
        url:
          this.$store.state.src +
          this.$store.state.subDirectory +
          "/Html/CaseList",
        type: "post",
        data: {
          Status: that.Status,
          Official_Status: that.Official_Status,
          Area: that.Area,
          Keyword: that.Keyword,
          page: page,
          perPage: that.perPage,
          token: $cookies.get("acm_login_token"),
          ShiftChange: that.ShiftChange,
          OnlyMe: that.OnlyMe,
          OrderBy: that.OrderBy,
        },
        dataType: "json",
        error: function () { },
        success: function (res) {
          that.CaseInfos = res.CaseInfos;
          that.perPage = res.perPage;
          that.pageAll = Math.max(Math.ceil(res.total / that.perPage), 1);
          $("html, body").animate({ scrollTop: 0 }, 500);
          that.CheckAll = false;
        },
      });
    },
    checkOutsider: function () {
      if (this.CIIds.length == 0) {
        Swal.fire({
          title: "錯誤",
          text: "未選取任何項目",
          icon: "error",
          allowOutsideClick: false,
          confirmButtonText: "確定",
          heightAuto: false,
        });
        return;
      }
      Swal.fire({
        title: "請稍候...",
        allowOutsideClick: false,
        showConfirmButton: false,
        heightAuto: false,
        willOpen: function () {
          Swal.showLoading();
        },
      });
      let that = this;
      $.ajax({
        url:
          this.$store.state.src +
          this.$store.state.subDirectory +
          "/Html/CheckOutsider",
        type: "post",
        data: {
          CIIds: that.CIIds,
          token: $cookies.get("acm_login_token"),
        },
        dataType: "json",
        error: function () { },
        success: function (res) {
          // 判斷結果
          if (res.ok) {
            Swal.fire({
              title: "簽收完成",
              icon: "success",
              allowOutsideClick: false,
              showConfirmButton: false,
              heightAuto: false,
              timer: 1500,
              willClose: () => {
                that.Status = 1;
                that.Official_Status = 6;
                that.loadList(1);
              },
            });
          } else if (res.error) {
            Swal.fire({
              title: "錯誤",
              text: res.error,
              icon: "error",
              allowOutsideClick: false,
              confirmButtonText: "確定",
              heightAuto: false,
              willClose: () => {
                if (res.token_error) {
                  $cookies.remove("acm_login_token");
                  that.$router.push({
                    name: "Login",
                  });
                }
              },
            });
          }
        },
      });
    },
    shiftChange: function () {
      if (this.CIIds.length == 0) {
        Swal.fire({
          title: "錯誤",
          text: "未選取任何項目",
          icon: "error",
          allowOutsideClick: false,
          confirmButtonText: "確定",
          heightAuto: false,
        });
        return;
      }
      Swal.fire({
        title: "請稍候...",
        allowOutsideClick: false,
        showConfirmButton: false,
        heightAuto: false,
        willOpen: function () {
          Swal.showLoading();
        },
      });
      let that = this;
      $.ajax({
        url:
          this.$store.state.src +
          this.$store.state.subDirectory +
          "/Html/ShiftChange",
        type: "post",
        data: {
          CIIds: that.CIIds,
          token: $cookies.get("acm_login_token"),
        },
        dataType: "json",
        error: function () { },
        success: function (res) {
          // 判斷結果
          if (res.ok) {
            Swal.fire({
              title: "換班完成",
              icon: "success",
              allowOutsideClick: false,
              showConfirmButton: false,
              heightAuto: false,
              timer: 1500,
              willClose: () => {
                that.Status = 1;
                that.Official_Status = 6;
                that.loadList(1);
              },
            });
          } else if (res.error) {
            Swal.fire({
              title: "錯誤",
              text: res.error,
              icon: "error",
              allowOutsideClick: false,
              confirmButtonText: "確定",
              heightAuto: false,
              willClose: () => {
                if (res.token_error) {
                  $cookies.remove("acm_login_token");
                  that.$router.push({
                    name: "Login",
                  });
                }
              },
            });
          }
        },
      });
    },
    checkIn: function (CIId) {
      Swal.fire({
        title: "請稍候...",
        allowOutsideClick: false,
        showConfirmButton: false,
        heightAuto: false,
        willOpen: function () {
          Swal.showLoading();
        },
      });
      let that = this;
      // 取得目前位置
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          function (pos) {
            const crd = pos.coords;
            $.ajax({
              url:
                that.$store.state.src +
                that.$store.state.subDirectory +
                "/Html/CheckIn",
              type: "post",
              data: {
                CIId: CIId,
                lat: crd.latitude,
                lng: crd.longitude,
                token: $cookies.get("acm_login_token"),
              },
              dataType: "json",
              error: function () { },
              success: function (res) {
                // 判斷結果
                if (res.ok) {
                  Swal.fire({
                    title: "打卡完成",
                    text: `${crd.latitude}, ${crd.longitude}`,
                    icon: "success",
                    allowOutsideClick: false,
                    confirmButtonText: "確定",
                    heightAuto: false,
                  });
                } else if (res.error) {
                  Swal.fire({
                    title: "錯誤",
                    text: res.error,
                    icon: "error",
                    allowOutsideClick: false,
                    confirmButtonText: "確定",
                    heightAuto: false,
                    willClose: () => {
                      if (res.token_error) {
                        $cookies.remove("acm_login_token");
                        that.$router.push({
                          name: "Login",
                        });
                      }
                    },
                  });
                }
              },
            });
          },
          function (err) {
            Swal.fire({
              title: "錯誤",
              text: `取得定位失敗(${err.code}): ${err.message}`,
              icon: "error",
              allowOutsideClick: false,
              confirmButtonText: "確定",
              heightAuto: false,
            });
          },
          {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0,
          }
        );
      } else {
        Swal.fire({
          title: "錯誤",
          text: "無法取得定位",
          icon: "error",
          allowOutsideClick: false,
          confirmButtonText: "確定",
          heightAuto: false,
        });
      }
    },
    gotoServiceView(CIId) {
      const routeData = this.$router.resolve({ name: 'CaseServiceView', query: { CIId: CIId } });
      window.open(routeData.href, '_blank');
    },
    forView(caseInfoId) {
      var sRet = "";
      if (caseInfoId && caseInfoId.length == 8 + 5) {
        sRet = caseInfoId.substr(0, 8);
        sRet += "-";
        sRet += caseInfoId.substr(8);
      } else {
        sRet = caseInfoId;
      }
      return sRet;
    },
  },
};
</script>