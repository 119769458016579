import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    //src: 'https://acm.thinker.tw',
    //subDirectory: '/Platform',
    //src: 'https://localhost:44321',
    //subDirectory: '',
    src: 'https://ahiqosws.ntpc.gov.tw',
    subDirectory: '/Platform',
    //src: 'https://localhost',
    //subDirectory: 'Platform',
    user: {
      token: "",
      isLogin: false
    }
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
