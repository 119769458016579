<template>
  <div>
    <div class="wrapper">
      <Head />
      <!-- 內容 -->
      <section class="main new_case" v-if="CaseInfo">
        <div class="container small">
          <div class="title">
            <h1 v-if="CaseInfo.CId == -1">新增案件</h1>
            <h1 v-if="CaseInfo.CId != -1">
              編輯案件 {{ forView(CaseInfo.CaseInfoId) }}
            </h1>
          </div>
          <div class="btn">
            <button @click="TabPage = 0" :class="TabPage == 0 ? 'add' : ''">
              案件登記
            </button>
            <button @click="TabPage = 1" :class="TabPage == 1 ? 'add' : ''" v-show="CaseInfo.CaseSequence">
              指派外勤人員
            </button>
            <button @click="TabPage = 2" :class="TabPage == 2 ? 'add' : ''" v-show="CaseInfo.ShiftChange">
              重派外勤人員
            </button>
          </div>
          <div class="show_box" :class="TabPage == 0 ? 'show' : ''">
            <div class="box">
              <div class="form">
                <div class="form-group case">
                  <label for="">案件類型<span style="color: red">*</span></label>
                  <select class="form-control" v-model="CaseInfo.CTId" data-required="1" data-required-msg="請選擇案件類型">
                    <option v-for="(CaseType, index) in CaseTypes" :key="index" :text="CaseType.Text"
                      :value="CaseType.Value">
                      {{ CaseType.Text }}
                    </option>
                  </select>
                  <button type="button" class="" data-toggle="modal" data-target="#caseTypeModal"></button>
                </div>
                <div class="form-group">
                  <label for="">處理排序</label>
                  <div>
                    <div class="form-check form-check-inline" style="min-width: 60px">
                      <input id="CaseSequence_0" class="form-check-input" type="radio"
                        @click="CaseInfo.CaseSequence = 0" :checked="CaseInfo.CaseSequence == 0" />
                      <label class="form-check-label" for="CaseSequence_0">
                        一般
                      </label>
                    </div>
                    <div class="form-check form-check-inline" style="min-width: 60px">
                      <input id="CaseSequence_1" class="form-check-input" type="radio" value="1"
                        @click="CaseInfo.CaseSequence = 1" :checked="CaseInfo.CaseSequence == 1" />
                      <label class="form-check-label" for="CaseSequence_1">
                        優先
                      </label>
                    </div>
                    <div class="form-check form-check-inline" style="min-width: 60px">
                      <input id="CaseSequence_2" class="form-check-input" type="radio" value="2"
                        @click="CaseInfo.CaseSequence = 2" :checked="CaseInfo.CaseSequence == 2" />
                      <label class="form-check-label" for="CaseSequence_2">
                        簡易
                      </label>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label for="">陳情人姓名<span style="color: red">*</span></label>
                  <input type="text" class="form-control" placeholder="請輸入姓名" v-model="CaseInfo.InquirerName"
                    data-required="1" data-required-msg="請輸入陳情人姓名" />
                </div>
                <div class="form-group">
                  <label for="">陳情人身分證字號</label>
                  <input type="text" class="form-control" placeholder="請輸入身分證字號" v-model="CaseInfo.InquirerIdno" />
                </div>
                <div class="form-group phone">
                  <label for="">陳情人手機</label>
                  <input type="text" class="form-control" placeholder="請輸入手機" v-model="CaseInfo.InquirerPhone" />
                </div>
                <div class="form-group space"></div>
                <div class="form-group">
                  <label for="">陳情人家用電話</label>
                  <input type="text" class="form-control" placeholder="請輸入家用電話" v-model="CaseInfo.InquirerHomePhone" />
                </div>
                <div class="form-group">
                  <label for="">分機</label>
                  <input type="text" class="form-control" placeholder="請輸入分機"
                    v-model="CaseInfo.InquirerPhoneExtension" />
                </div>
                <div class="form-group address">
                  <label for="">陳情人聯絡地址</label>
                  <input type="text" class="form-control" placeholder="請輸入聯絡地址" v-model="CaseInfo.InquirerAddress" />
                </div>
                <div class="form-group">
                  <label for="">通報時間<span style="color: red">*</span></label>
                  <input type="datetime-local" class="form-control" v-model="CaseInfo.InquirerTime" data-required="1"
                    data-required-msg="請選擇通報時間" />
                </div>
              </div>
            </div>
            <div class="box">
              <div class="form">
                <div class="form-group textarea">
                  <label for="">案件摘要<span style="color: red">*</span></label>
                  <textarea class="form-control" rows="5" placeholder="請輸入案件摘要" v-model="CaseInfo.Summary"
                    data-required="1" data-required-msg="請輸入案件摘要"></textarea>
                </div>
                <div class="form-group">
                  <label for="">案發區域<span style="color: red">*</span></label>
                  <select class="form-control" v-model="CaseInfo.Area" data-required="1" data-required-msg="請選擇案發區域">
                    <option v-for="(Area, index) in Areas" :key="index" :text="Area.Text" :value="Area.Value">
                      {{ Area.Text }}
                    </option>
                  </select>
                </div>
                <div class="form-group address">
                  <label for="">案發地點<span style="color: red">*</span></label>
                  <div>
                    <input type="text" class="form-control" placeholder="請輸入案發地點" v-model="CaseInfo.Address"
                      style="width: 100%" data-required="1" data-required-msg="請輸入案發地點" />
                  </div>
                </div>
                <div class="form-group">
                  <label for="">指派單位</label>
                  <select id="selectCaseUnit" class="form-control" v-model="CaseInfo.CUId" data-required="1"
                    data-required-msg="請選擇指派單位">
                    <option v-for="(CaseUnit, index) in CaseUnits" :key="index" :text="CaseUnit.Text"
                      :value="CaseUnit.Value" v-show="CaseUnit.Text.indexOf(CaseInfo.Area) != -1">
                      {{ CaseUnit.Text }}
                    </option>
                  </select>
                </div>
                <!-- <div class="form-group class">
                  <label for="">班別</label>
                  <select class="form-control" v-model="F_TimePeriod" disabled>
                    <option
                      v-for="(TimePeriod, index) in TimePeriods"
                      :key="index"
                      :text="TimePeriod.Text"
                      :value="TimePeriod.Value"
                    >
                      {{ TimePeriod.Text }}
                    </option>
                  </select>
                  <small id="" class="form-text text-muted"
                    >*此欄位暫時不使用</small
                  >
                </div> -->
                <div class="form-group name">
                  <label for="">姓名</label>
                  <input type="text" class="form-control" placeholder="請輸入姓名" v-model="CaseInfo.PerpName" />

                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="perpUnknown"
                      @click="CaseInfo.PerpUnknown = !CaseInfo.PerpUnknown" :checked="CaseInfo.PerpUnknown" />
                    <label class="form-check-label" for="perpUnknown">不詳</label>
                  </div>
                </div>
                <div class="form-group">
                  <label for="">行為人性別</label>
                  <div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" id="perpGender_1" @click="CaseInfo.PerpGender = true"
                        :checked="CaseInfo.PerpGender" />
                      <label class="form-check-label" for="perpGender_1">
                        男
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" id="perpGender_2"
                        @click="CaseInfo.PerpGender = false" :checked="!CaseInfo.PerpGender" />
                      <label class="form-check-label" for="perpGender_2">
                        女
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="box">
              <h2>動物別</h2>
              <div class="form">
                <div class="form-group animal">
                  <label for="">狗</label>
                  <select class="form-control" v-model="CaseInfo.AnimalDogQty"
                    style="flex-basis: 40%; text-align: right">
                    <option value="0">0</option>
                    <option :value="n" v-for="n in 100" :key="n">
                      {{ n }}
                    </option>
                  </select>
                  <!-- <input
                    class="form-control"
                    placeholder="0"
                    type="number"
                    v-model="CaseInfo.AnimalDogQty"
                  /> -->
                  <p>隻</p>
                </div>
                <div class="form-group animal">
                  <label for="">貓</label>
                  <select class="form-control" v-model="CaseInfo.AnimalCatQty"
                    style="flex-basis: 40%; text-align: right">
                    <option value="0">0</option>
                    <option :value="n" v-for="n in 100" :key="n">
                      {{ n }}
                    </option>
                  </select>
                  <!-- <input
                    class="form-control"
                    placeholder="0"
                    type="number"
                    v-model="CaseInfo.AnimalCatQty"
                  /> -->
                  <p>隻</p>
                </div>
                <div class="form-group animal">
                  <label for="">野生鳥類</label>
                  <select class="form-control" v-model="CaseInfo.AnimalBirdQty"
                    style="flex-basis: 40%; text-align: right">
                    <option value="0">0</option>
                    <option :value="n" v-for="n in 100" :key="n">
                      {{ n }}
                    </option>
                  </select>
                  <!-- <input
                    class="form-control"
                    placeholder="0"
                    type="number"
                    v-model="CaseInfo.AnimalBirdQty"
                  /> -->
                  <p>隻</p>
                </div>
                <div class="form-group animal">
                  <label for="">經濟動物(含鴿子)</label>
                  <select class="form-control" v-model="CaseInfo.AnimalPFTQty"
                    style="flex-basis: 40%; text-align: right">
                    <option value="0">0</option>
                    <option :value="n" v-for="n in 100" :key="n">
                      {{ n }}
                    </option>
                  </select>
                  <!-- <input
                    class="form-control"
                    placeholder="0"
                    type="number"
                    v-model="CaseInfo.AnimalPFTQty"
                  /> -->
                  <p>隻</p>
                </div>
                <div class="form-group animal">
                  <label for="">蜂</label>
                  <select class="form-control" v-model="CaseInfo.AnimalBeeQty"
                    style="flex-basis: 40%; text-align: right">
                    <option value="0">0</option>
                    <option :value="n" v-for="n in 100" :key="n">
                      {{ n }}
                    </option>
                  </select>
                  <!-- <input
                    class="form-control"
                    placeholder="0"
                    type="number"
                    v-model="CaseInfo.AnimalBeeQty"
                  /> -->
                  <p>隻</p>
                </div>
                <div class="form-group animal">
                  <label for="">蛇</label>
                  <select class="form-control" v-model="CaseInfo.AnimalSnakeQty"
                    style="flex-basis: 40%; text-align: right">
                    <option value="0">0</option>
                    <option :value="n" v-for="n in 100" :key="n">
                      {{ n }}
                    </option>
                  </select>
                  <!-- <input
                    class="form-control"
                    placeholder="0"
                    type="number"
                    v-model="CaseInfo.AnimalSnakeQty"
                  /> -->
                  <p>隻</p>
                </div>
                <div class="form-group animal">
                  <label for="">其他動物</label>
                  <select class="form-control" v-model="CaseInfo.AnimalOtherQty"
                    style="flex-basis: 40%; text-align: right">
                    <option value="0">0</option>
                    <option :value="n" v-for="n in 100" :key="n">
                      {{ n }}
                    </option>
                  </select>
                  <!-- <input
                    class="form-control"
                    placeholder="0"
                    type="number"
                    v-model="CaseInfo.AnimalOtherQty"
                  /> -->
                  <p>隻</p>
                </div>
              </div>
            </div>
            <div class="box">
              <h2>種類</h2>
              <div class="form type">
                <div class="form-group animal">
                  <label for="">野生鳥類</label>
                  <input type="text" class="form-control" placeholder="請輸入種類" v-model="CaseInfo.AnimalBirdKind" />
                </div>
                <div class="form-group animal">
                  <label for="">經濟動物</label>
                  <input type="text" class="form-control" placeholder="請輸入種類" v-model="CaseInfo.AnimalPFTKind" />
                </div>
                <div class="form-group animal">
                  <label for="">蜂</label>
                  <input type="text" class="form-control" placeholder="請輸入種類" v-model="CaseInfo.AnimalBeeKind" />
                </div>
                <div class="form-group animal">
                  <label for="">蛇</label>
                  <input type="text" class="form-control" placeholder="請輸入種類" v-model="CaseInfo.AnimalSnakeKind" />
                </div>
                <div class="form-group animal">
                  <label for="">其他動物</label>
                  <input type="text" class="form-control" placeholder="請輸入種類" v-model="CaseInfo.AnimalOtherKind" />
                </div>
              </div>
            </div>
            <div class="box">
              <h2>報表相關</h2>
              <div class="form-group">
                <label for="">情況描述具體內容</label>
                <select class="form-control" v-model="CaseInfo.SituationDesc" data-required="1"
                  data-required-msg="請選擇事項">
                  <option value="">-</option>
                  <option value="動物用藥">動物用藥</option>
                  <option value="飼主照護責任">飼主照護責任</option>
                  <option value="棄養動物">棄養動物</option>
                  <option value="虐待、傷害動物">虐待、傷害動物</option>
                  <option value="展演動物">展演動物</option>
                  <option value="動物侵害他人">動物侵害他人</option>
                  <option value="飼養禁止飼養、輸出入動物">
                    飼養禁止飼養、輸出入動物
                  </option>
                  <option value="動物運輸">動物運輸</option>
                  <option value="賭博性動物搏鬥及競技">
                    賭博性動物搏鬥及競技
                  </option>
                  <option value="動物醫療">動物醫療</option>
                  <option value="任意宰殺">任意宰殺</option>
                  <option value="宰殺動物方式不當">宰殺動物方式不當</option>
                  <option value="製造、販賣、陳列或輸出入獸鋏">
                    製造、販賣、陳列或輸出入獸鋏
                  </option>
                  <option value="實驗動物管理">實驗動物管理</option>
                  <option value="未辦理寵物登記">未辦理寵物登記</option>
                  <option value="犬隻疏縱(含具攻擊性犬管理)">
                    犬隻疏縱(含具攻擊性犬管理)
                  </option>
                  <option value="未經許可經營寵物繁殖、買賣及寄養">
                    未經許可經營寵物繁殖、買賣及寄養
                  </option>
                  <option value="寵物業者管理不善">寵物業者管理不善</option>
                  <option value="免絕育及繁殖申報">免絕育及繁殖申報</option>
                  <option value="寵物食品">寵物食品</option>
                  <option value="寵物食品查驗">寵物食品查驗</option>
                  <option value="散布不當影音">散布不當影音</option>
                  <option value="不得飼養及禁止認養">不得飼養及禁止認養</option>
                  <option value="規避、妨礙或拒絕動保稽查">
                    規避、妨礙或拒絕動保稽查
                  </option>
                  <option value="獸鋏">獸鋏</option>
                  <option value="宰殺犬貓或販售犬貓屠體">
                    宰殺犬貓或販售犬貓屠體
                  </option>
                  <option value="金屬套索陷阱（山豬吊）">
                    金屬套索陷阱（山豬吊）
                  </option>
                  <option value="其他不當捕捉方式">其他不當捕捉方式</option>
                  <option value="非經許可展演動物">非經許可展演動物</option>
                  <option value="將不擬繼續飼養之動物送交動物收容處所">
                    將不擬繼續飼養之動物送交動物收容處所
                  </option>
                  <option value="其他(不列入月報)">其他(不列入月報)</option>
                </select>
              </div>
              <div class="form-group">
                <label for="">事項<span style="color: red">*</span></label>
                <select class="form-control" v-model="CaseInfo.Situation" data-required="1" data-required-msg="請選擇事項">
                  <option value="">-</option>
                  <option value="易見非法買賣寵物之地點">
                    易見非法買賣寵物之地點
                  </option>
                  <option value="藉由網路等媒體買賣寵物者">
                    藉由網路等媒體買賣寵物者
                  </option>
                  <option value="易見非法寄養寵物之地點">
                    易見非法寄養寵物之地點
                  </option>
                  <option value="無特定對象">無特定對象</option>
                </select>
              </div>
              <div class="form-group textarea">
                <label for="">其它記載事項</label>
                <textarea class="form-control" rows="5" placeholder="請輸入其它記載事項" v-model="CaseInfo.SituationOther"
                  data-required="0" data-required-msg="請輸入其它記載事項"></textarea>
              </div>
            </div>
            <div class="box">
              <h2>其他</h2>
              <div class="form-group name">
                <label for="">型態</label>
                <input type="text" class="form-control" placeholder="請輸入型態" v-model="CaseInfo.Type" />
              </div>
              <div class="form-group textarea">
                <label for="">備註</label>
                <textarea class="form-control" rows="5" placeholder="請輸入備註" v-model="CaseInfo.Remark"></textarea>
              </div>
            </div>
          </div>
          <div class="show_box" :class="TabPage == 1 ? 'show' : ''" v-show="CaseInfo.CaseSequence != 0">
            <div class="box">
              <div class="form">
                <div class="form-group textarea">
                  <label for="">外勤人員<span style="color: red">*</span></label>
                  <select class="form-control" id="selectOutsider" v-model="CaseInfo.Outsider">
                    <option v-for="(Outsider, index) in Outsiders" :key="index" :text="Outsider.Text"
                      :value="Outsider.Value">
                      {{ Outsider.Text }}
                    </option>
                  </select>
                </div>
                <div class="form-group textarea">
                  <label for="">勤務說明</label>
                  <textarea class="form-control" rows="5" placeholder="請輸入勤務說明"
                    v-model="CaseInfo.PerpDutyDescription"></textarea>
                </div>
              </div>
            </div>
          </div>
          <div class="show_box" :class="TabPage == 2 ? 'show' : ''" v-show="CaseInfo.ShiftChange">
            <div class="box">
              <div class="form">
                <div class="form-group textarea">
                  <label for="">重派外勤人員</label>
                  <select class="form-control" id="selectAllOutsider" v-model="ShiftOutsider">
                    <option v-for="(Outsider, index) in AllOutsiders" :key="index" :text="Outsider.Text"
                      :value="Outsider.Value">
                      {{ Outsider.Text }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="btn_box">
            <button @click="save()">儲存變更</button>
            <router-link :to="{ path: '/CaseList', query: {} }" tag="button">取消</router-link>
          </div>
        </div>
      </section>
    </div>
    <!-- Modal -->
    <div class="modal fade case" id="caseTypeModal" tabindex="-1" aria-labelledby="caseTypeModalLabel"
      aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content">
          <div class="modal-body">
            <div class="bg">
              <h3 class="modal-title" id="caseTypeModalLabel">案件類型</h3>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="row">
              <div class="col-12 col-sm-6 col-md-4" v-for="(CaseType, index) in CaseTypes" :key="index">
                <button @click="CaseInfo.CTId = CaseType.Value" data-dismiss="modal">
                  {{ CaseType.Text }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Head from "@/components/Head.vue";
import Footer from "@/components/Footer.vue";
import AES from "@/utils/aes.js";
import VueCookies from "vue-cookies";

import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";

Vue.use(VueAxios, axios);

export default {
  name: "CaseEdit",
  components: {
    Head,
    Footer,
  },
  data() {
    return {
      Member: null,

      TabPage: 0,

      CaseTypes: null,
      Areas: null,
      CaseUnits: null,
      TimePeriods: null,
      Outsiders: null,
      AllOutsiders: null,

      CaseInfo: null,
      IsLoaded: false,

      ShiftOutsider: null,
      Force: false,
    };
  },
  watch: {
    TabPage(newVal, oldVal) {
      if (newVal == 2) {
        let that = this;
        $.ajax({
          type: "post",
          url:
            that.$store.state.src +
            that.$store.state.subDirectory +
            "/Html/GetOutsiders",
          data: {
            CUId: -1,
          },
          success: function (result) {
            $("#selectAllOutsider").find("option").remove();
            $("#selectAllOutsider").append('<option value="">-</option>');
            var data = jQuery.parseJSON(result.items);
            $.each(data, function (i, item) {
              $("#selectAllOutsider").append(
                '<option value="' + item.id + '">' + item.text + "</option>"
              );
            });
          },
        });
      }
    },
    CaseSequence(newVal, oldVal) {
      this.CaseInfo.CaseSequence = newVal == "1";
    },
    "CaseInfo.Area": function (newVal, oldVal) {
      if (this.CaseInfo.CIId != -1 && !this.IsLoaded) {
        this.IsLoaded = true;
        return true;
      }
      let that = this;
      that.CaseInfo.CUId = -1;
      that.CaseUnits.forEach(function (CaseUnit, index) {
        if (CaseUnit.Text.indexOf(newVal) != -1) {
          that.CaseInfo.CUId = CaseUnit.Value;
          return true;
        }
      });
    },
    "CaseInfo.CUId": function (newVal, oldVal) {
      let that = this;
      $.ajax({
        type: "post",
        url:
          that.$store.state.src +
          that.$store.state.subDirectory +
          "/Html/GetOutsiders",
        data: {
          CUId: newVal,
        },
        success: function (result) {
          $("#selectOutsider").find("option").remove();
          var findOutsider = false;
          var data = jQuery.parseJSON(result.items);
          $.each(data, function (i, item) {
            if (that.CaseInfo.Outsider == item.id) {
              findOutsider = true;
            }
            $("#selectOutsider").append(
              '<option value="' + item.id + '">' + item.text + "</option>"
            );
          });
          if (!findOutsider) {
            that.CaseInfo.Outsider = null;
          }
          // $("#selectOutsider").val($("#selectOutsider option:first").val());
        },
      });
    },
  },
  created: function () {
    Vue.axios
      .get(
        this.$store.state.src +
        this.$store.state.subDirectory +
        "/Html/CaseEdit",
        {
          params: {
            CIId: this.$route.query.CIId,
            token: $cookies.get("acm_login_token"),
          },
        }
      )
      .then((response) => {
        var CaseAddData = response.data;
        if (CaseAddData.error) {
          Swal.fire({
            title: "錯誤",
            text: CaseAddData.error,
            icon: "error",
            allowOutsideClick: false,
            confirmButtonText: "確定",
            heightAuto: false,
            willClose: () => {
              if (CaseAddData.token_error) {
                $cookies.remove("acm_login_token");
                this.$router.push({
                  name: "Login",
                });
              } else if (that.CaseAddData.goback) {
                this.$router.push({
                  name: "CaseList",
                });
              }
            },
          });
        } else {
          this.Member = CaseAddData.Member;
          this.CaseTypes = CaseAddData.CaseTypes;
          this.Areas = CaseAddData.Areas;
          this.CaseUnits = CaseAddData.CaseUnits;
          this.TimePeriods = CaseAddData.TimePeriods;
          this.Outsiders = CaseAddData.Outsiders;
          this.CaseInfo = CaseAddData.CaseInfo;

          this.CaseInfo.InquirerTime = this.convertDatetimeString(
            this.CaseInfo.InquirerTime
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  mounted: function () { },
  updated: function () { },
  methods: {
    save() {
      var hasError = false;
      $("[data-required='1']").each(function () {
        var input = $(this);
        if (!input.val()) {
          Swal.fire({
            title: "錯誤",
            text: input.data("required-msg"),
            icon: "error",
            allowOutsideClick: false,
            confirmButtonText: "確定",
            heightAuto: false,
          });
          hasError = true;
          return false;
        }
      });
      if (hasError) {
        return;
      }
      if (this.CaseInfo.CTId == -1) {
        Swal.fire({
          title: "錯誤",
          text: "請選擇案件類型",
          icon: "error",
          allowOutsideClick: false,
          confirmButtonText: "確定",
          heightAuto: false,
        });
        return;
      }
      if (
        this.CaseInfo.InquirerPhone != null &&
        this.CaseInfo.InquirerPhone != "" &&
        (!this.CaseInfo.InquirerPhone.startsWith("09") ||
          this.CaseInfo.InquirerPhone.length != 10)
      ) {
        Swal.fire({
          title: "錯誤",
          text: "陳情人手機格式錯誤",
          icon: "error",
          allowOutsideClick: false,
          confirmButtonText: "確定",
          heightAuto: false,
        });
        return;
      }
      Swal.fire({
        title: "請稍候...",
        allowOutsideClick: false,
        showConfirmButton: false,
        heightAuto: false,
        willOpen: function () {
          Swal.showLoading();
        },
      });
      let that = this;
      $.ajax({
        url:
          that.$store.state.src +
          that.$store.state.subDirectory +
          "/Html/CaseEdit",
        type: "post",
        data: {
          CIId: that.CaseInfo.CIId,
          CTId: that.CaseInfo.CTId,
          InquirerName:
            that.CaseInfo.InquirerName == null
              ? ""
              : that.CaseInfo.InquirerName,
          InquirerIdno:
            that.CaseInfo.InquirerIdno == null
              ? ""
              : that.CaseInfo.InquirerIdno,
          InquirerPhone:
            that.CaseInfo.InquirerPhone == null
              ? ""
              : that.CaseInfo.InquirerPhone,
          InquirerHomePhone:
            that.CaseInfo.InquirerHomePhone == null
              ? ""
              : that.CaseInfo.InquirerHomePhone,
          InquirerPhoneExtension:
            that.CaseInfo.InquirerPhoneExtension == null
              ? ""
              : that.CaseInfo.InquirerPhoneExtension,
          InquirerAddress:
            that.CaseInfo.InquirerAddress == null
              ? ""
              : that.CaseInfo.InquirerAddress,
          InquirerTime: that.CaseInfo.InquirerTime,
          Summary: that.CaseInfo.Summary == null ? "" : that.CaseInfo.Summary,
          Area: that.CaseInfo.Area,
          Address: that.CaseInfo.Address == null ? "" : that.CaseInfo.Address,
          CUId: that.CaseInfo.CUId,
          PerpGender: that.CaseInfo.PerpGender,
          PerpName:
            that.CaseInfo.PerpName == null ? "" : that.CaseInfo.PerpName,
          PerpUnknown: that.CaseInfo.PerpUnknown,
          PerpDutyDescription:
            that.CaseInfo.PerpDutyDescription == null
              ? ""
              : that.CaseInfo.PerpDutyDescription,
          AnimalDogQty: that.CaseInfo.AnimalDogQty,
          AnimalCatQty: that.CaseInfo.AnimalCatQty,
          AnimalBirdQty: that.CaseInfo.AnimalBirdQty,
          AnimalPFTQty: that.CaseInfo.AnimalPFTQty,
          AnimalBeeQty: that.CaseInfo.AnimalBeeQty,
          AnimalSnakeQty: that.CaseInfo.AnimalSnakeQty,
          AnimalOtherQty: that.CaseInfo.AnimalOtherQty,
          AnimalBirdKind:
            that.CaseInfo.AnimalBirdKind == null
              ? ""
              : that.CaseInfo.AnimalBirdKind,
          AnimalPFTKind:
            that.CaseInfo.AnimalPFTKind == null
              ? ""
              : that.CaseInfo.AnimalPFTKind,
          AnimalBeeKind:
            that.CaseInfo.AnimalBeeKind == null
              ? ""
              : that.CaseInfo.AnimalBeeKind,
          AnimalSnakeKind:
            that.CaseInfo.AnimalSnakeKind == null
              ? ""
              : that.CaseInfo.AnimalSnakeKind,
          AnimalOtherKind:
            that.CaseInfo.AnimalOtherKind == null
              ? ""
              : that.CaseInfo.AnimalOtherKind,
          CaseSequence: that.CaseInfo.CaseSequence,
          Outsider:
            that.CaseInfo.Outsider == null ? "" : that.CaseInfo.Outsider,
          ShiftOutsider: that.ShiftOutsider == null ? "" : that.ShiftOutsider,
          SituationDesc: that.CaseInfo.SituationDesc,
          Situation: that.CaseInfo.Situation,
          SituationOther: that.CaseInfo.SituationOther == null ? "" : that.CaseInfo.SituationOther,
          Type: that.CaseInfo.Type == null ? "" : that.CaseInfo.Type,
          Remark: that.CaseInfo.Remark == null ? "" : that.CaseInfo.Remark,
          Force: that.Force,
          token: $cookies.get("acm_login_token"),
        },
        dataType: "json",
        error: function () { },
        success: function (res) {
          // 判斷結果
          if (res.isRepeat) {
            Swal.fire({
              title: "重覆案件確認",
              text:
                "相同地址已有相同類型的案件(" +
                that.forView(res.CaseInfoId) +
                "), 請確定是否仍要送出此案件?",
              heightAuto: false,
              showCancelButton: true,
              confirmButtonText: "確定",
              cancelButtonText: "取消",
              showLoaderOnConfirm: true,
              allowOutsideClick: false,
            }).then((result) => {
              if (result.isConfirmed) {
                that.Force = true;
                that.save();
              } else {
                return;
              }
            });
          } else if (res.ok) {
            Swal.fire({
              title: "儲存完成",
              icon: "success",
              allowOutsideClick: false,
              showConfirmButton: false,
              heightAuto: false,
              timer: 1500,
              willClose: () => {
                // if (that.CaseInfo.CIId == -1) {
                //   that.$router.push({
                //     name: "CaseEdit",
                //     query: { CIId: res.CIId },
                //   });
                //   location.reload();
                // } else {
                //   location.reload();
                // }
                that.$router.push({
                  name: "CaseList",
                  query: {},
                });
              },
            });
          } else if (res.error) {
            Swal.fire({
              title: "錯誤",
              text: res.error,
              icon: "error",
              allowOutsideClick: false,
              confirmButtonText: "確定",
              heightAuto: false,
              willClose: () => {
                if (res.token_error) {
                  $cookies.remove("acm_login_token");
                  that.$router.push({
                    name: "Login",
                  });
                }
              },
            });
          }
        },
      });
    },
    convertDatetimeString(time) {
      var date = new Date(
        time.replace(
          /^(\d{4})(\d\d)(\d\d)(\d\d)(\d\d)(\d\d)$/,
          "$4:$5:$6 $2/$3/$1"
        )
      );
      return moment(date).format("YYYY-MM-DDTHH:mm");
    },
    forView(caseInfoId) {
      var sRet = "";
      if (caseInfoId && caseInfoId.length == 8 + 5) {
        sRet = caseInfoId.substr(0, 8);
        sRet += "-";
        sRet += caseInfoId.substr(8);
      } else {
        sRet = caseInfoId;
      }
      return sRet;
    },
  },
};
</script>