<template>
  <div>
    <div class="wrapper">
      <Head />
      <!-- 內容 -->
      <section
        class="main case_registration"
        v-if="CaseInfoServiceLog"
        style="pointer-events:none;"
      >
        <div class="container small">
          <div class="title">
            <h1 v-if="CaseInfoServiceLog.CISLId == -1">新增勤務登記</h1>
            <h1 v-if="CaseInfoServiceLog.CISLId != -1">編輯勤務登記</h1>
          </div>
          <div class="btn">
            <button class="add">
              值勤內容
              <div class="plus"></div>
            </button>
          </div>
          <div>
            <div class="box">
              <div class="file_box">
                <p>附加檔案</p>
                <div id="preview_imgs" style="" class="row mt-3">
                  <div
                    class="col-4"
                    v-for="(Attachment, index) in Attachments"
                    :key="index"
                  >
                    <a
                      :title="Attachment.Name"
                      :href="Attachment.Url"
                      target="_blank"
                      ><img
                        :alt="Attachment.Name"
                        :src="Attachment.Url"
                        style="width: 100%; heigt: auto"
                    /></a>
                  </div>
                </div>
              </div>
            </div>
            <div class="box">
              <div class="form">
                <div class="form-group" style="flex-basis: 100%">
                  <label for=""
                    >出勤日期<span style="color: red">*</span></label
                  >
                  <input
                    type="datetime-local"
                    class="form-control"
                    v-model="CaseInfoServiceLog.ServiceTime"
                    data-required="1"
                    data-required-msg="請選出勤日期"
                  />
                </div>
                <div class="form-group" v-if="false">
                  <label for="">狀態<span style="color: red">*</span></label>
                  <select
                    class="form-control"
                    v-model="CaseInfoServiceLog.Status"
                    data-required="1"
                    data-required-msg="請選擇狀態"
                  >
                    <option
                      v-for="(Status, index) in StatusList"
                      :key="index"
                      :text="Status.Text"
                      :value="Status.Value"
                    >
                      {{ Status.Text }}
                    </option>
                  </select>
                </div>
                <div class="form-group textarea">
                  <label for=""
                    >值勤內容概述<span style="color: red">*</span></label
                  >
                  <textarea
                    class="form-control"
                    rows="5"
                    placeholder="請輸入值勤內容概述"
                    v-model="CaseInfoServiceLog.Summary"
                    data-required="1"
                    data-required-msg="請輸入值勤內容概述"
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
          <div class="btn">
            <button class="add">
              巡察結果
              <div class="plus"></div>
            </button>
          </div>
          <div>
            <div class="box">
              <div class="form">
                <div class="form-group animal">
                  <label for="">已收容動物隻數_狗</label>
                  <select
                    class="form-control"
                    v-model="CaseInfoServiceLog.Dog"
                    style="flex-basis: calc(100% - 28px); text-align: right"
                  >
                    <option value="0">0</option>
                    <option :value="n" v-for="n in 100" :key="n">
                      {{ n }}
                    </option>
                  </select>
                  <!-- <input
                    type="number"
                    class="form-control"
                    placeholder="0"
                    v-model="CaseInfoServiceLog.Dog"
                  /> -->
                  <p>隻</p>
                </div>
                <div class="form-group animal">
                  <label for="">已收容動物隻數_哺乳</label>
                  <select
                    class="form-control"
                    v-model="CaseInfoServiceLog.Mammals"
                    style="flex-basis: calc(100% - 28px); text-align: right"
                  >
                    <option value="0">0</option>
                    <option :value="n" v-for="n in 100" :key="n">
                      {{ n }}
                    </option>
                  </select>
                  <!-- <input
                    type="number"
                    class="form-control"
                    placeholder="0"
                    v-model="CaseInfoServiceLog.Mammals"
                  /> -->
                  <p>隻</p>
                </div>
                <div class="form-group animal">
                  <label for="">已收容動物隻數_貓</label>
                  <select
                    class="form-control"
                    v-model="CaseInfoServiceLog.Cat"
                    style="flex-basis: calc(100% - 28px); text-align: right"
                  >
                    <option value="0">0</option>
                    <option :value="n" v-for="n in 100" :key="n">
                      {{ n }}
                    </option>
                  </select>
                  <!-- <input
                    type="number"
                    class="form-control"
                    placeholder="0"
                    v-model="CaseInfoServiceLog.Cat"
                  /> -->
                  <p>隻</p>
                </div>
                <div class="form-group animal">
                  <label for="">已收容動物隻數_家禽</label>
                  <select
                    class="form-control"
                    v-model="CaseInfoServiceLog.Poultry"
                    style="flex-basis: calc(100% - 28px); text-align: right"
                  >
                    <option value="0">0</option>
                    <option :value="n" v-for="n in 100" :key="n">
                      {{ n }}
                    </option>
                  </select>
                  <!-- <input
                    type="number"
                    class="form-control"
                    placeholder="0"
                    v-model="CaseInfoServiceLog.Poultry"
                  /> -->
                  <p>隻</p>
                </div>
                <div class="form-group animal">
                  <label for="">已收容動物隻數_鳥類</label>
                  <select
                    class="form-control"
                    v-model="CaseInfoServiceLog.Bird"
                    style="flex-basis: calc(100% - 28px); text-align: right"
                  >
                    <option value="0">0</option>
                    <option :value="n" v-for="n in 100" :key="n">
                      {{ n }}
                    </option>
                  </select>
                  <!-- <input
                    type="number"
                    class="form-control"
                    placeholder="0"
                    v-model="CaseInfoServiceLog.Bird"
                  /> -->
                  <p>隻</p>
                </div>
                <div class="form-group animal">
                  <label for="">已收容動物隻數_其他</label>
                  <select
                    class="form-control"
                    v-model="CaseInfoServiceLog.Other"
                    style="flex-basis: calc(100% - 28px); text-align: right"
                  >
                    <option value="0">0</option>
                    <option :value="n" v-for="n in 100" :key="n">
                      {{ n }}
                    </option>
                  </select>
                  <!-- <input
                    type="number"
                    class="form-control"
                    placeholder="0"
                    v-model="CaseInfoServiceLog.Other"
                  /> -->
                  <p>隻</p>
                </div>
              </div>
            </div>
            <div class="box">
              <div class="form">
                <div class="form-group full">
                  <label for="">未能收容原因</label>
                  <div>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="radio"
                        id="UncontainedReason_0"
                        @click="CaseInfoServiceLog.UncontainedReason = 0"
                        :checked="CaseInfoServiceLog.UncontainedReason == 0"
                      />
                      <label class="form-check-label" for="UncontainedReason_0">
                        已全數收容
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="radio"
                        id="UncontainedReason_1"
                        @click="CaseInfoServiceLog.UncontainedReason = 1"
                        :checked="CaseInfoServiceLog.UncontainedReason == 1"
                      />
                      <label class="form-check-label" for="UncontainedReason_1">
                        未發現(犬/貓)隻
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="radio"
                        id="UncontainedReason_2"
                        @click="CaseInfoServiceLog.UncontainedReason = 2"
                        :checked="CaseInfoServiceLog.UncontainedReason == 2"
                      />
                      <label class="form-check-label" for="UncontainedReason_2">
                        聞風而逃
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="radio"
                        id="UncontainedReason_3"
                        @click="CaseInfoServiceLog.UncontainedReason = 3"
                        :checked="CaseInfoServiceLog.UncontainedReason == 3"
                      />
                      <label class="form-check-label" for="UncontainedReason_3">
                        地形因素
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="radio"
                        id="UncontainedReason_4"
                        @click="CaseInfoServiceLog.UncontainedReason = 4"
                        :checked="CaseInfoServiceLog.UncontainedReason == 4"
                      />
                      <label class="form-check-label" for="UncontainedReason_4">
                        逕行驅離
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="radio"
                        id="UncontainedReason_5"
                        @click="CaseInfoServiceLog.UncontainedReason = 5"
                        :checked="CaseInfoServiceLog.UncontainedReason == 5"
                      />
                      <label class="form-check-label" for="UncontainedReason_5">
                        其他處理情形
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="box" v-show="CaseInfoServiceLog.UncontainedReason == 5">
              <div class="form">
                <div class="form-group full">
                  <label for="">其他處理情形</label>
                  <div>
                    <div
                      class="form-check form-check-inline"
                      style="flex-basis: calc(100% / 2)"
                    >
                      <input
                        class="form-check-input"
                        type="radio"
                        id="OtherMethod_1"
                        @click="CaseInfoServiceLog.OtherMethod = 1"
                        :checked="CaseInfoServiceLog.OtherMethod == 1"
                      />
                      <label class="form-check-label" for="OtherMethod_1">
                        誘(犬/貓)籠已交陳情人使用
                      </label>
                    </div>
                    <div
                      class="form-check form-check-inline"
                      style="flex-basis: calc(100% / 2)"
                    >
                      <input
                        class="form-check-input"
                        type="radio"
                        id="OtherMethod_2"
                        @click="CaseInfoServiceLog.OtherMethod = 2"
                        :checked="CaseInfoServiceLog.OtherMethod == 2"
                      />
                      <label class="form-check-label" for="OtherMethod_2">
                        誘(犬/貓)籠已換籠
                      </label>
                    </div>
                    <div
                      class="form-check form-check-inline"
                      style="flex-basis: calc(100% / 2)"
                    >
                      <input
                        class="form-check-input"
                        type="radio"
                        id="OtherMethod_3"
                        @click="CaseInfoServiceLog.OtherMethod = 3"
                        :checked="CaseInfoServiceLog.OtherMethod == 3"
                      />
                      <label class="form-check-label" for="OtherMethod_3">
                        誘(犬/貓)籠已帶回
                      </label>
                    </div>
                    <div
                      class="form-check form-check-inline"
                      style="flex-basis: calc(100% / 2)"
                    >
                      <input
                        class="form-check-input"
                        type="radio"
                        id="OtherMethod_4"
                        @click="CaseInfoServiceLog.OtherMethod = 4"
                        :checked="CaseInfoServiceLog.OtherMethod == 4"
                      />
                      <label class="form-check-label" for="OtherMethod_4">
                        送至動保處/動物之家/動物醫院
                      </label>
                    </div>
                  </div>
                  <div></div>
                </div>
                <div
                  class="form-group"
                  v-show="CaseInfoServiceLog.OtherMethod == 4"
                >
                  <label for=""
                    >送至<span
                      style="color: red"
                      v-if="
                        CaseInfoServiceLog.UncontainedReason == 5 &&
                        CaseInfoServiceLog.OtherMethod == 4
                      "
                      >*</span
                    ></label
                  >
                  <input
                    type="text"
                    class="form-control"
                    placeholder="請輸入送至何處"
                    v-model="CaseInfoServiceLog.MoveTo"
                    :data-required="
                      CaseInfoServiceLog.UncontainedReason == 5 &&
                      CaseInfoServiceLog.OtherMethod == 4
                        ? '1'
                        : '0'
                    "
                    data-required-msg="請輸入送至何處"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="btn">
            <button class="add">
              現場處理通知
              <div class="plus"></div>
            </button>
          </div>
          <div>
            <div class="box">
              <div class="form">
                <div class="form-group full">
                  <label for="">處理時間</label>
                  <input
                    type="datetime-local"
                    class="form-control"
                    v-model="CaseInfoServiceLog.MoveTime"
                  />
                  <div>
                    <div class="form-check">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="isTogether"
                        v-model="CaseInfoServiceLog.IsTogether"
                      />
                      <label class="form-check-label" for="isTogether"
                        >是否會同辦理</label
                      >
                    </div>
                  </div>
                  <div
                    class="form-group"
                    style="
                      padding-left: 15px;
                      margin-top: 0.5rem;
                      margin-bottom: 0.5rem;
                    "
                    v-show="!CaseInfoServiceLog.IsTogether"
                  >
                    <input
                      type="text"
                      class="form-control"
                      placeholder="請輸入未會同辦理原因"
                      v-model="CaseInfoServiceLog.NoTogetherReason"
                      :data-required="
                        !CaseInfoServiceLog.IsTogether ? '1' : '0'
                      "
                      data-required-msg="請輸入未會同辦理原因"
                    />
                  </div>
                  <div>
                    <div class="form-check">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="missedCall"
                        v-model="CaseInfoServiceLog.MissedCall"
                      />
                      <label class="form-check-label" for="missedCall"
                        >是否未接電話</label
                      >
                    </div>
                  </div>
                  <div
                    class="form-group"
                    style="
                      padding-left: 15px;
                      margin-top: 0.5rem;
                      margin-bottom: 0.5rem;
                    "
                    v-show="CaseInfoServiceLog.MissedCall"
                  >
                    <input
                      type="text"
                      class="form-control"
                      placeholder="請輸入未接電話原因"
                      v-model="CaseInfoServiceLog.MissedCallReason"
                      :data-required="CaseInfoServiceLog.MissedCall ? '1' : '0'"
                      data-required-msg="請輸入未接電話原因"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="box">
              <div class="form">
                <div class="form-group full">
                  <label for="">是否已聯繫</label>
                  <div class="basis_3">
                    <div class="form-check">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="connectInquirer"
                        v-model="CaseInfoServiceLog.ConnectInquirer"
                      />
                      <label class="form-check-label" for="connectInquirer"
                        >陳情人</label
                      >
                    </div>
                    <div class="form-check">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="connectChiefOfVillage"
                        v-model="CaseInfoServiceLog.ConnectChiefOfVillage"
                      />
                      <label
                        class="form-check-label"
                        for="connectChiefOfVillage"
                        >里長</label
                      >
                    </div>
                    <div class="form-check">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="connectNiceGuy"
                        v-model="CaseInfoServiceLog.ConnectNiceGuy"
                      />
                      <label class="form-check-label" for="connectNiceGuy"
                        >當地愛心團體(餵養人士)</label
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="btn">
            <button class="add">
              現場評估事項
              <div class="plus"></div>
            </button>
          </div>
          <div class="box">
            <div class="form">
              <div class="form-group full">
                <div class="form-check">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    id="hasNiceGuy"
                    v-model="CaseInfoServiceLog.HasNiceGuy"
                  />
                  <label class="form-check-label" for="hasNiceGuy"
                    >有無餵養人士</label
                  >
                </div>
                <div
                  class="form-group"
                  style="
                    padding-left: 15px;
                    margin-top: 0.5rem;
                    margin-bottom: 0.5rem;
                  "
                  v-show="CaseInfoServiceLog.HasNiceGuy"
                >
                  <input
                    type="text"
                    class="form-control"
                    placeholder="請輸入姓名"
                    v-model="CaseInfoServiceLog.NiceGuyName"
                    :data-required="CaseInfoServiceLog.HasNiceGuy ? '1' : '0'"
                    data-required-msg="請輸入姓名"
                  />
                </div>
                <div
                  class="form-group"
                  style="
                    padding-left: 15px;
                    margin-top: 0.5rem;
                    margin-bottom: 0.5rem;
                  "
                  v-show="CaseInfoServiceLog.HasNiceGuy"
                >
                  <input
                    type="text"
                    class="form-control"
                    placeholder="請輸入電話"
                    v-model="CaseInfoServiceLog.NiceGuyPhone"
                    :data-required="CaseInfoServiceLog.HasNiceGuy ? '1' : '0'"
                    data-required-msg="請輸入電話"
                  />
                </div>
                <div class="form-check">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    id="isTNVR"
                    v-model="CaseInfoServiceLog.IsTNVR"
                  />
                  <label class="form-check-label" for="isTNVR"
                    >是否列入TNVR</label
                  >
                </div>
                <div
                  class="form-group"
                  style="
                    padding-left: 15px;
                    margin-top: 0.5rem;
                    margin-bottom: 0.5rem;
                  "
                  v-show="CaseInfoServiceLog.IsTNVR"
                >
                  <select
                    class="form-control"
                    v-model="CaseInfoServiceLog.TNVRCount"
                    :data-required="CaseInfoServiceLog.IsTNVR ? '1' : '0'"
                    data-required-msg="請輸入TNVR隻數"
                    style="flex-basis: calc(100% - 28px); text-align: right"
                  >
                    <option value="0">0</option>
                    <option :value="n" v-for="n in 100" :key="n">
                      {{ n }}
                    </option>
                  </select>
                  <!-- <input
                    type="number"
                    class="form-control"
                    placeholder="請輸入TNVR隻數"
                    v-model="CaseInfoServiceLog.TNVRCount"
                    :data-required="CaseInfoServiceLog.IsTNVR ? '1' : '0'"
                    data-required-msg="請輸入TNVR隻數"
                  /> -->
                </div>
                <div class="form-check">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    id="isSpecial"
                    v-model="CaseInfoServiceLog.IsSpecial"
                  />
                  <label class="form-check-label" for="isSpecial"
                    >特殊情況</label
                  >
                </div>
                <div
                  class="form-group"
                  style="
                    padding-left: 15px;
                    margin-top: 0.5rem;
                    margin-bottom: 0.5rem;
                  "
                  v-show="CaseInfoServiceLog.IsSpecial"
                >
                  <select
                    class="form-control"
                    v-model="CaseInfoServiceLog.SpecialCount"
                    :data-required="CaseInfoServiceLog.IsSpecial ? '1' : '0'"
                    data-required-msg="請輸入特殊情況隻數"
                    style="flex-basis: calc(100% - 28px); text-align: right"
                  >
                    <option value="0">0</option>
                    <option :value="n" v-for="n in 100" :key="n">
                      {{ n }}
                    </option>
                  </select>
                  <!-- <input
                    type="number"
                    class="form-control"
                    placeholder="請輸入特殊情況隻數"
                    v-model="CaseInfoServiceLog.SpecialCount"
                    :data-required="CaseInfoServiceLog.IsSpecial ? '1' : '0'"
                    data-required-msg="請輸入特殊情況隻數"
                  /> -->
                </div>
              </div>
            </div>
          </div>
          <div class="btn" v-show="CaseType.IsNeedReportInfo">
            <button class="add">
              家戶訪查清查狀態
              <div class="plus"></div>
            </button>
          </div>
          <div class="box" v-show="CaseType.IsNeedReportInfo">
            <div class="form">
              <div class="form-group full">
                <div class="basis_2">
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="radio"
                      id="InventoryStatus_1"
                      @click="CaseInfoServiceLog.InventoryStatus = 1"
                      :checked="CaseInfoServiceLog.InventoryStatus == 1"
                    />
                    <label class="form-check-label" for="InventoryStatus_1">
                      開始清查
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="radio"
                      id="InventoryStatus_2"
                      @click="CaseInfoServiceLog.InventoryStatus = 2"
                      :checked="CaseInfoServiceLog.InventoryStatus == 2"
                    />
                    <label class="form-check-label" for="InventoryStatus_2">
                      犬隻因身體免絕育證明
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="radio"
                      id="InventoryStatus_3"
                      @click="CaseInfoServiceLog.InventoryStatus = 3"
                      :checked="CaseInfoServiceLog.InventoryStatus == 3"
                    />
                    <label class="form-check-label" for="InventoryStatus_3">
                      無狗吠聲
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="radio"
                      id="InventoryStatus_4"
                      @click="CaseInfoServiceLog.InventoryStatus = 4"
                      :checked="CaseInfoServiceLog.InventoryStatus == 4"
                    />
                    <label class="form-check-label" for="InventoryStatus_4">
                      無此戶
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="radio"
                      id="InventoryStatus_5"
                      @click="CaseInfoServiceLog.InventoryStatus = 5"
                      :checked="CaseInfoServiceLog.InventoryStatus == 5"
                    />
                    <label class="form-check-label" for="InventoryStatus_5">
                      無人應門
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="radio"
                      id="InventoryStatus_6"
                      @click="CaseInfoServiceLog.InventoryStatus = 6"
                      :checked="CaseInfoServiceLog.InventoryStatus == 6"
                    />
                    <label class="form-check-label" for="InventoryStatus_6">
                      無人居住
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="radio"
                      id="InventoryStatus_7"
                      @click="CaseInfoServiceLog.InventoryStatus = 7"
                      :checked="CaseInfoServiceLog.InventoryStatus == 7"
                    />
                    <label class="form-check-label" for="InventoryStatus_7">
                      狗不配合調查
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="radio"
                      id="InventoryStatus_8"
                      @click="CaseInfoServiceLog.InventoryStatus = 8"
                      :checked="CaseInfoServiceLog.InventoryStatus == 8"
                    />
                    <label class="form-check-label" for="InventoryStatus_8">
                      無飼養犬貓
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="radio"
                      id="InventoryStatus_9"
                      @click="CaseInfoServiceLog.InventoryStatus = 9"
                      :checked="CaseInfoServiceLog.InventoryStatus == 9"
                    />
                    <label class="form-check-label" for="InventoryStatus_9">
                      其他原因
                    </label>
                  </div>
                </div>
              </div>
              <div class="form-group animal">
                <label for="">清查犬隻總數</label>
                <select
                  class="form-control"
                  v-model="CaseInfoServiceLog.InventoryDog"
                  style="flex-basis: calc(100% - 28px); text-align: right"
                >
                  <option value="0">0</option>
                  <option :value="n" v-for="n in 100" :key="n">
                    {{ n }}
                  </option>
                </select>
                <p>隻</p>
              </div>
              <div class="form-group animal">
                <label for="">已登記數</label>
                <select
                  class="form-control"
                  v-model="CaseInfoServiceLog.Registered"
                  style="flex-basis: calc(100% - 28px); text-align: right"
                >
                  <option value="0">0</option>
                  <option :value="n" v-for="n in 100" :key="n">
                    {{ n }}
                  </option>
                </select>
                <p>隻</p>
              </div>
              <div class="form-group animal">
                <label for="">已絕育數</label>
                <select
                  class="form-control"
                  v-model="CaseInfoServiceLog.Sterilization"
                  style="flex-basis: calc(100% - 28px); text-align: right"
                >
                  <option value="0">0</option>
                  <option :value="n" v-for="n in 100" :key="n">
                    {{ n }}
                  </option>
                </select>
                <p>隻</p>
              </div>
              <div class="form-group animal">
                <label for="">免絕育數</label>
                <select
                  class="form-control"
                  v-model="CaseInfoServiceLog.NoNeedSterilization"
                  style="flex-basis: calc(100% - 28px); text-align: right"
                >
                  <option value="0">0</option>
                  <option :value="n" v-for="n in 100" :key="n">
                    {{ n }}
                  </option>
                </select>
                <p>隻</p>
              </div>
              <div class="form-group animal">
                <label for="">已注射疫苗數</label>
                <select
                  class="form-control"
                  v-model="CaseInfoServiceLog.Vaccination"
                  style="flex-basis: calc(100% - 28px); text-align: right"
                >
                  <option value="0">0</option>
                  <option :value="n" v-for="n in 100" :key="n">
                    {{ n }}
                  </option>
                </select>
                <p>隻</p>
              </div>
              <div class="form-group animal">
                <label for="">母犬數</label>
                <select
                  class="form-control"
                  v-model="CaseInfoServiceLog.FemaleDog"
                  style="flex-basis: calc(100% - 28px); text-align: right"
                >
                  <option value="0">0</option>
                  <option :value="n" v-for="n in 100" :key="n">
                    {{ n }}
                  </option>
                </select>
                <p>隻</p>
              </div>
              <div class="form-group">
                <label for="">編號</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="請輸入編號"
                  v-model="CaseInfoServiceLog.No"
                />
              </div>
            </div>
          </div>
          <div class="btn" v-show="CaseType.IsNeedReportInfo">
            <button class="add">
              遊蕩犬清查狀態
              <div class="plus"></div>
            </button>
          </div>
          <div class="box" v-show="CaseType.IsNeedReportInfo">
            <div class="form">
              <div class="form-group full">
                <label for="">是否有主人</label>
                <div>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="radio"
                      id="MasterStatus_1"
                      @click="CaseInfoServiceLog.MasterStatus = 1"
                      :checked="CaseInfoServiceLog.MasterStatus == 1"
                    />
                    <label class="form-check-label" for="MasterStatus_1">
                      無主犬
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="radio"
                      id="MasterStatus_2"
                      @click="CaseInfoServiceLog.MasterStatus = 2"
                      :checked="CaseInfoServiceLog.MasterStatus == 2"
                    />
                    <label class="form-check-label" for="MasterStatus_2">
                      有主犬
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="radio"
                      id="MasterStatus_0"
                      @click="CaseInfoServiceLog.MasterStatus = 0"
                      :checked="CaseInfoServiceLog.MasterStatus == 0"
                    />
                    <label class="form-check-label" for="MasterStatus_0">
                      不確定
                    </label>
                  </div>
                </div>
              </div>
              <hr class="line" />
              <div class="form-group full">
                <label for="">是否絕育</label>
                <div>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="radio"
                      id="SterilizationStatus_1"
                      @click="CaseInfoServiceLog.SterilizationStatus = 1"
                      :checked="CaseInfoServiceLog.SterilizationStatus == 1"
                    />
                    <label class="form-check-label" for="SterilizationStatus_1">
                      未絕育
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="radio"
                      id="SterilizationStatus_2"
                      @click="CaseInfoServiceLog.SterilizationStatus = 2"
                      :checked="CaseInfoServiceLog.SterilizationStatus == 2"
                    />
                    <label class="form-check-label" for="SterilizationStatus_2">
                      絕育
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="radio"
                      id="SterilizationStatus_0"
                      @click="CaseInfoServiceLog.SterilizationStatus = 0"
                      :checked="CaseInfoServiceLog.SterilizationStatus == 0"
                    />
                    <label class="form-check-label" for="SterilizationStatus_0">
                      不確定
                    </label>
                  </div>
                </div>
              </div>
              <hr class="line" />
              <div class="form-group full">
                <label for="">處置狀態</label>
                <div>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="radio"
                      id="DisposalStatus_1"
                      @click="CaseInfoServiceLog.DisposalStatus = 1"
                      :checked="CaseInfoServiceLog.DisposalStatus == 1"
                    />
                    <label class="form-check-label" for="DisposalStatus_1">
                      紀錄
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="radio"
                      id="DisposalStatus_2"
                      @click="CaseInfoServiceLog.DisposalStatus = 2"
                      :checked="CaseInfoServiceLog.DisposalStatus == 2"
                    />
                    <label class="form-check-label" for="DisposalStatus_2">
                      抓捕
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="radio"
                      id="DisposalStatus_3"
                      @click="CaseInfoServiceLog.DisposalStatus = 3"
                      :checked="CaseInfoServiceLog.DisposalStatus == 3"
                    />
                    <label class="form-check-label" for="DisposalStatus_3">
                      回置
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="radio"
                      id="DisposalStatus_4"
                      @click="CaseInfoServiceLog.DisposalStatus = 4"
                      :checked="CaseInfoServiceLog.DisposalStatus == 4"
                    />
                    <label class="form-check-label" for="DisposalStatus_4">
                      死亡
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="radio"
                      id="DisposalStatus_5"
                      @click="CaseInfoServiceLog.DisposalStatus = 5"
                      :checked="CaseInfoServiceLog.DisposalStatus == 5"
                    />
                    <label class="form-check-label" for="DisposalStatus_5">
                      不見了
                    </label>
                  </div>
                </div>
              </div>
              <hr class="line" />
              <div class="form-group full">
                <label for="">花色(可複選)</label>
                <div>
                  <div class="form-check">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      id="Color_Black"
                      v-model="CaseInfoServiceLog.Color_Black"
                    />
                    <label class="form-check-label" for="Color_Black">黑</label>
                  </div>
                  <div class="form-check">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      id="Color_White"
                      v-model="CaseInfoServiceLog.Color_White"
                    />
                    <label class="form-check-label" for="Color_White">白</label>
                  </div>
                  <div class="form-check">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      id="Color_Yellow"
                      v-model="CaseInfoServiceLog.Color_Yellow"
                    />
                    <label class="form-check-label" for="Color_Yellow"
                      >黃</label
                    >
                  </div>
                  <div class="form-check">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      id="Color_Spot"
                      v-model="CaseInfoServiceLog.Color_Spot"
                    />
                    <label class="form-check-label" for="Color_Spot">花</label>
                  </div>
                  <div class="form-check">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      id="Color_BlackAndWhite"
                      v-model="CaseInfoServiceLog.Color_BlackAndWhite"
                    />
                    <label class="form-check-label" for="Color_BlackAndWhite"
                      >黑白</label
                    >
                  </div>
                  <div class="form-check">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      id="Color_BlackAndYellow"
                      v-model="CaseInfoServiceLog.Color_BlackAndYellow"
                    />
                    <label class="form-check-label" for="Color_BlackAndYellow"
                      >黑黃</label
                    >
                  </div>
                  <div class="form-check">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      id="Color_WhiteAndYellow"
                      v-model="CaseInfoServiceLog.Color_WhiteAndYellow"
                    />
                    <label class="form-check-label" for="Color_WhiteAndYellow"
                      >白黃</label
                    >
                  </div>
                </div>
              </div>
              <hr />
              <div class="form-group">
                <label for="">種類</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="請輸入種類"
                  v-model="CaseInfoServiceLog.Kind"
                />
              </div>
              <div class="form-group">
                <label for="">寵物登記</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="請輸入寵物登記"
                  v-model="CaseInfoServiceLog.PetRegister"
                />
              </div>
            </div>
          </div>
          <div class="btn" v-show="CaseType.IsNeedReportInfo">
            <button class="add">
              餵食者清查狀況
              <div class="plus"></div>
            </button>
          </div>
          <div class="box" v-show="CaseType.IsNeedReportInfo">
            <div class="form">
              <div class="form-group full">
                <label for="">處理類型</label>
                <div>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="radio"
                      id="FeederStatus_1"
                      @click="CaseInfoServiceLog.FeederStatus = 1"
                      :checked="CaseInfoServiceLog.FeederStatus == 1"
                    />
                    <label class="form-check-label" for="FeederStatus_1">
                      紀錄
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="radio"
                      id="FeederStatus_2"
                      @click="CaseInfoServiceLog.FeederStatus = 2"
                      :checked="CaseInfoServiceLog.FeederStatus == 2"
                    />
                    <label class="form-check-label" for="FeederStatus_2">
                      勸導
                    </label>
                  </div>
                </div>
              </div>
              <div class="form-group textarea">
                <label for="">地點說明</label>
                <textarea
                  class="form-control"
                  rows="5"
                  placeholder="請輸入地點說明"
                  v-model="CaseInfoServiceLog.FeederContent"
                ></textarea>
              </div>
            </div>
          </div>
          <div class="btn" v-show="CaseType.IsNeedReportInfo">
            <button class="add">
              野生動物資訊
              <div class="plus"></div>
            </button>
          </div>
          <div class="box" v-show="CaseType.IsNeedReportInfo">
            <div class="form">
              <div class="form-group">
                <label for="">型態</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="請輸入型態"
                  v-model="CaseInfoServiceLog.Type"
                />
              </div>
              <div class="form-group">
                <label for="">備註</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="請輸入備註"
                  v-model="CaseInfoServiceLog.Remark"
                />
              </div>
            </div>
          </div>
          <div class="btn_box">
          </div>
        </div>
      </section>
    </div>
    <!-- Modal -->
    <!-- <div
      class="modal fade case"
      id="signModal"
      tabindex="-1"
      aria-labelledby="signModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content">
          <div class="modal-body">
            <div class="bg">
              <h3 class="modal-title" id="signModalLabel">電子簽名</h3>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div
              id="signPaintBlock"
              style="width: 100%; height: 200px; border: solid 1px black"
            >
              <canvas id="signPaint"></canvas>
            </div>
            <div class="btn_box">
              <button id="export" class="send" data-dismiss="modal">
                確認
              </button>
              <button id="reset">清除</button>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <Footer />
  </div>
</template>

<script>
import Head from "@/components/Head.vue";
import Footer from "@/components/Footer.vue";
import AES from "@/utils/aes.js";
import VueCookies from "vue-cookies";

import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";

Vue.use(VueAxios, axios);

export default {
  name: "CaseServiceView",
  components: {
    Head,
    Footer,
  },
  data() {
    return {
      Member: null,
      StatusList: null,
      CaseInfo: null,
      CaseType: null,
      CaseInfoServiceLog: null,
      Attachments: [],
    };
  },
  created: function () {
    Vue.axios
      .get(
        this.$store.state.src +
          this.$store.state.subDirectory +
          "/Html/CaseServiceView",
        {
          params: {
            CIId: this.$route.query.CIId,
            token: $cookies.get("acm_login_token"),
          },
        }
      )
      .then((response) => {
        var CaseServiceData = response.data;
        if (CaseServiceData.error) {
          Swal.fire({
            title: "錯誤",
            text: CaseServiceData.error,
            icon: "error",
            allowOutsideClick: false,
            confirmButtonText: "確定",
            heightAuto: false,
            willClose: () => {
              if (CaseServiceData.token_error) {
                $cookies.remove("acm_login_token");
                this.$router.push({
                  name: "Login",
                });
              } else if (CaseServiceData.goback) {
                this.$router.push({
                  name: "CaseList",
                });
              }
            },
          });
        } else {
          this.StatusList = CaseServiceData.StatusList;
          this.CaseInfo = CaseServiceData.CaseInfo;
          this.CaseType = CaseServiceData.CaseType;
          this.CaseInfoServiceLog = CaseServiceData.CaseInfoServiceLog;

          if (this.CaseInfoServiceLog.Attachment) {
            this.Attachments = JSON.parse(this.CaseInfoServiceLog.Attachment);
          }

          if (this.CaseInfoServiceLog.ServiceTime) {
            this.CaseInfoServiceLog.ServiceTime = this.convertDatetimeString(
              this.CaseInfoServiceLog.ServiceTime
            );
          }

          if (this.CaseInfoServiceLog.MoveTime) {
            this.CaseInfoServiceLog.MoveTime = this.convertDatetimeString(
              this.CaseInfoServiceLog.MoveTime
            );
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  mounted: function () {
  },
  updated: function () {
    $(".case_registration.main .btn button").click(function () {
      $(this).find(".plus").toggleClass("add");
      $(this).parent().next().slideToggle();
    });
  },
  methods: {
    convertDatetimeString(time) {
      var date = new Date(
        time.replace(
          /^(\d{4})(\d\d)(\d\d)(\d\d)(\d\d)(\d\d)$/,
          "$4:$5:$6 $2/$3/$1"
        )
      );
      return moment(date).format("YYYY-MM-DDTHH:mm");
    },
    signClear() {
      $("#signature").jSignature("reset");
    },
    signSave() {
      $(".imported").attr("src", $("#signature").jSignature("getData"));
      $(".main .box .sign_box button").text("重新簽名");
      $(".sign_modal").removeClass("add");
      $("body").removeClass("add");
    },
    signShow() {
      $(".sign_modal").addClass("add");
      $("body").addClass("add");
      if (!this.jSignatureInit) {
        $("#signature").jSignature({
          width: "100%",
          height: "200px",
          color: "#000",
          "background-color": "transparent",
        });
        this.jSignatureInit = true;
      } else {
        $("#signature").jSignature("reset");
      }
    },
    signClose() {
      $(".sign_modal").removeClass("add");
      $("body").removeClass("add");
    },
    signSend() {
      $(".sign_modal").removeClass("add");
      $("body").removeClass("add");
    },
  },
};
</script>